import Matomo from '@package/components/theme/Matomo/Matomo';
import CustomCSS from '@package/components/theme/CustomCSS/CustomCSS';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,

    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: CustomCSS,
        props: {
          href: 'https://koho.apptest.jyu.fi/api/custom.css'
        },
      },
      {
        match: '',
        component: Matomo,
      },
    ],

    // Enable multilingual
    isMultilingual: true,
    supportedLanguages: ['fi', 'en', 'sv'],
    defaultLanguage: 'fi',
  };

  return config;
}
