/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { useRef } from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { CookieConsentLink } from 'volto-cookie-consent-addon';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const consentRef = useRef();
  return (
    <>
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        color="grey"
        textAlign="center"
      >
        <Container>
          <List horizontal inverted>
            {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
            <div role="listitem" className="item">
              <Link className="item" to="/fi/ota-yhteytta">
                <FormattedMessage id="Contact" defaultMessage="Contact" />
              </Link>{' '}
              -{' '}
              <Link className="item" to="/fi/saavutettavuusseloste-koho">
                <FormattedMessage
                  id="Accessibility"
                  defaultMessage="Accessibility"
                />
              </Link>{' '}
              - <CookieConsentLink consentRef={consentRef} />
            </div>
          </List>
        </Container>
      </Segment>
      <div ref={consentRef} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
