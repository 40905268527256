import React, { useEffect } from 'react';

const CustomCSS = ({ href }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return <></>;
};

export default CustomCSS;